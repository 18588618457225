import React from 'react';
// import CustomForm from 'components/Form/CustomForm';
import { NavLink, useOutletContext } from 'react-router-dom';
import { COLOR_GREEN } from 'constants/colors';
import styled from 'styled-components';
import Alert from 'components/Elements/Alert';
import {
  getSclaAppBackgroundPath,
  getSclaAppSchoolPath,
} from 'utils/navigation';
import useScrollToTop from 'hooks/useScrollToTop';
import FamilyForm from 'components/Form/FamilyForm';
import useUpdateSealsAppFamily from '../hooks/useUpdateSclaAppFamily';
import { SclaApplicationOutletContext } from './SclaAppLayout';

// import ApplicationAlertInfo from '../components/ApplicationAlertInfo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px 16px 50px 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h1 {
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 28px;
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const NavButtonLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  background-color: ${COLOR_GREEN};
  padding: 10px 15px;
  border-radius: 4px;
  p {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
  }
`;

export default function SclaApplicationFamily() {
  useScrollToTop();
  const { application, setApplication }: SclaApplicationOutletContext =
    useOutletContext();

  const { formValue, isLoading, model, fields, onChange, onSubmit } =
    useUpdateSealsAppFamily(application, setApplication);

  return (
    <>
      <Header>
        <h1 style={{ fontSize: '2.1em' }}>Family Information</h1>
        <NavButtons>
          <NavButtonLink to={getSclaAppBackgroundPath()}>
            <p>Previous</p>
          </NavButtonLink>
          <NavButtonLink to={getSclaAppSchoolPath()}>
            <p>Next</p>
          </NavButtonLink>
        </NavButtons>
        {/* <ApplicationAlertInfo /> */}

        <Alert type="info">
          <p>
            <b>Required</b> fields are marked with:{' '}
            <span style={{ color: 'red', fontSize: '1em' }}>
              * (Required)
            </span>
          </p>
          <p>
            <b>
              At URBAN TXT: Teens Exploring Technology, we value your
              personal journey. Please help us learn more about you and
              your family by answering the following questions:
            </b>
          </p>
        </Alert>
      </Header>

      <Container>
        <FamilyForm
          onChange={onChange}
          onSubmit={onSubmit}
          fields={fields}
          isLoading={isLoading}
          model={model}
          formValue={formValue}
          isDisabled={false}
          submitLabel="Save and Continue"
          hasChanges={false}
        />
      </Container>
    </>
  );
}
