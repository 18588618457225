import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import {
  getFormValidationStatus,
  handleNullString,
  scrollToFormErr,
} from 'utils/form.util';
import { FormField } from 'components/Form/CustomForm';
import { SclaApplication } from 'features/scla/types';
import updateSclaAppBackground from 'features/scla/api/updateSclaAppBackground';
import useAppNavigation from 'hooks/useAppNavigation';
import { isDateString } from 'class-validator';
import {
  PICKER_ETHNICITY,
  PICKER_LANGUAGES,
  PICKER_SCLA_HEAR_ABOUT_US,
  PICKER_YES_NO,
  PICKER_GRADE,
  PICKER_ALL_STATES,
} from 'constants/picker';
import { openSuccessToaster } from 'services/toast.service';
import { useAppSelector } from '../../../store/hooks';
import { getUser } from '../../../store/slices/userSessionSlice';

// Extract schema types for form validation
const { StringType, DateType } = Schema.Types;

const errorMessages = {
  email: 'Please enter a valid email.',
  firstName: 'Please enter your first name.',
  lastName: 'Please enter your last name.',
  school: 'Please enter your school name.',
  ethnicity: 'Please select your ethnicity.',
  grade: 'Please select your grade level.',
  password: 'Please enter your password.',
  verifyPassword: 'Passwords do not match.',
  dob: 'Please enter your date of birth.',
  gradeLevel: 'Please enter your grade level.',
  studentPhoneNumber: 'Please enter your phone number.',
  parentPhoneNumber: `Please enter the best cell phone number that we can use to contact your parent/guardian.`,
  streetAddress: 'Please enter your street address.',
  cityAddress: 'Please enter your city.',
  stateAddress: 'Please enter your state.',
  zipAddress: 'Please enter your zip code.',
  instagramHandle: `Please enter your instagram handle. If you do not have one, enter 'N/A'`,
  primaryLanguage: 'Please select one',
  hearAboutUs: 'Please select one',
  appliedBefore: 'Please select one',
  transportation: 'Please select one',
};

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  firstName: StringType().isRequired(errorMessages.firstName),
  lastName: StringType().isRequired(errorMessages.lastName),
  email: StringType().isEmail().isRequired(errorMessages.email),
  studentPhoneNumber: StringType(),
  parentPhoneNumber: StringType().isRequired(
    errorMessages.parentPhoneNumber,
  ),
  streetAddress: StringType().isRequired(errorMessages.streetAddress),
  cityAddress: StringType().isRequired(errorMessages.cityAddress),
  stateAddress: StringType().isRequired(errorMessages.stateAddress),
  zipAddress: StringType().isRequired(errorMessages.zipAddress),
  dob: DateType().isRequired(errorMessages.dob),
  instagramHandle: StringType(),
  ethnicity: StringType().isRequired(errorMessages.ethnicity),
  gradeLevel: StringType().isRequired(errorMessages.gradeLevel),
  primaryLanguage: StringType().isRequired(errorMessages.primaryLanguage),
  appliedBefore: StringType().isRequired(errorMessages.appliedBefore),
  transportation: StringType().isRequired(errorMessages.transportation),
  hearAboutUs: StringType().isRequired(errorMessages.hearAboutUs),
});

export interface IUpdateSclaAppBackgroundForm {
  firstName: string;
  lastName: string;
  email: string;
  studentPhoneNumber: string;
  parentPhoneNumber: string;
  streetAddress: string;
  cityAddress: string;
  stateAddress: string;
  zipAddress: string;
  dob: Date;
  instagramHandle: string;
  ethnicity: string;
  ethnicityOther: string;
  gradeLevel: string;
  primaryLanguage: string;
  primaryLanguageOther: string;
  transportation: string;
  appliedBefore: string;
  hearAboutUs: string;
  hearAboutUsOther: string;
  recommendedBy: string; // New field for recommender's name
}

const INIT_FORM: IUpdateSclaAppBackgroundForm = {
  firstName: '',
  lastName: '',
  email: '',
  studentPhoneNumber: '',
  parentPhoneNumber: '',
  streetAddress: '',
  cityAddress: '',
  stateAddress: '',
  zipAddress: '',
  dob: new Date(),
  instagramHandle: '',
  ethnicity: '',
  ethnicityOther: '',
  gradeLevel: '',
  primaryLanguage: '',
  primaryLanguageOther: '',
  transportation: '',
  appliedBefore: '',
  hearAboutUs: '',
  hearAboutUsOther: '',
  recommendedBy: '', // Initialize the new field
};

const fields: FormField[] = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'email',
    type: 'text',
    label: 'Email Address',
    size: 'lg',
    data: null,
    required: true,
  },
  {
    name: 'dob',
    type: 'date',
    label: 'Date Of Birth',
    size: 'sm',
    data: null,
    required: true,
  },

  {
    name: 'instagramHandle',
    type: 'text',
    label: 'Instagram Handle',
    size: 'sm',
    data: null,
  },

  {
    name: 'studentPhoneNumber',
    type: 'phoneNumber',
    label: 'Student Phone Number',
    size: 'sm',
    data: null,
  },
  {
    name: 'parentPhoneNumber',
    type: 'phoneNumber',
    label: 'Parent/Guardian Phone Number',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'streetAddress',
    type: 'text',
    label: 'Street Address',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'cityAddress',
    type: 'text',
    label: 'City',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'stateAddress',
    type: 'select',
    label: 'State',
    size: 'sm',
    data: {
      otherLabel: 'Enter your state',
      picker: PICKER_ALL_STATES,
      searchable: true,
    },
    required: true,
  },
  {
    name: 'zipAddress',
    type: 'text',
    label: 'Zip Code',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'primaryLanguage',
    type: 'text',
    label: 'Primary Language Spoken At Home',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'ethnicity',
    type: 'select',
    label: 'Ethnicity',
    size: 'sm',
    data: {
      otherLabel: 'Enter your ethnicity',
      picker: PICKER_ETHNICITY,
      searchable: false,
    },
    required: true,
  },

  {
    name: 'hearAboutUs',
    type: 'select',
    label: 'How did you hear about the program?',
    size: 'sm',
    data: {
      otherLabel: 'Other',
      picker: PICKER_SCLA_HEAR_ABOUT_US,
      searchable: false,
    },
    required: true,
  },

  {
    name: 'appliedBefore',
    type: 'select',
    label: 'Have you applied before?',
    size: 'sm',
    data: {
      otherLabel: 'Other',
      picker: PICKER_YES_NO,
      searchable: false,
    },
    required: true,
  },

  {
    name: 'transportation',
    type: 'text',
    label: 'What is your primary source of transportation?',
    size: 'sm',
    // data: {
    //   otherLabel: 'Other',
    //   picker: PICKER_SCLA_TRANSPORTATION,
    //   searchable: false,
    // },
    data: null,
    required: true,
  },
  {
    name: 'gradeLevel',
    type: 'select',
    label: 'Grade Level',
    size: 'sm',
    data: {
      otherLabel: 'Enter your grade level',
      picker: PICKER_GRADE,
      searchable: false,
    },
    required: true,
  },
];

const checkIsEthnicityOther = (ethnicity: null | string) => {
  if (ethnicity) {
    // get string array of all ethnicity except 'other'
    const ethnicityStr = PICKER_ETHNICITY.filter(
      e => e.value.toLocaleLowerCase() !== 'other',
    ).map(e => e.value.toLocaleLowerCase());

    // is user ethnicity different from picker list
    return !ethnicityStr.includes(ethnicity.toLocaleLowerCase());
  }

  return false;
};

const checkIsLanguageOther = (language: null | string) => {
  if (language) {
    // get string array of languages except other
    const languageStr = PICKER_LANGUAGES.filter(
      e => e.value.toLocaleLowerCase() !== 'other',
    ).map(e => e.value.toLocaleLowerCase());

    // is language different from picker list
    return !languageStr.includes(language.toLocaleLowerCase());
  }

  return false;
};

const checkIsHearAboutUsOther = (hearAboutUs: null | string) => {
  if (hearAboutUs) {
    // get string array of languages except other
    const languageStr = PICKER_SCLA_HEAR_ABOUT_US.filter(
      e => e.value.toLocaleLowerCase() !== 'other',
    ).map(e => e.value.toLocaleLowerCase());

    // is language different from picker list
    return !languageStr.includes(hearAboutUs.toLocaleLowerCase());
  }

  return false;
};

// Function that checks if user chose 'Recommended by TXT Student' as to how they heard about the program
const checkIsHearAboutUsTxter = (hearAboutUs: null | string) => {
  if (hearAboutUs) {
    // console.log('hearAboutUs function', hearAboutUs);
    // // get string array of options except 'other'
    // const optionsStr = PICKER_SCLA_HEAR_ABOUT_US.filter(
    //   e => e.value !== 'Recommended by TXT Student',
    // ).map(e => e.value);

    // // is option different from picker list
    // return !optionsStr.includes(hearAboutUs.toLocaleLowerCase());
    return hearAboutUs === 'Recommended by TXT Student';
  }

  return false;
};

export default function useUpdateSclaAppBackground(
  app: SclaApplication,
  setApplication: (val: SclaApplication) => void,
) {
  const [formValue, setFormValue] =
    useState<IUpdateSclaAppBackgroundForm>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process

  const { navToSclaAppFamily } = useAppNavigation();

  // fetch user profile
  const user = useAppSelector(getUser);

  // console.log('data', data);

  const mapDefaultVales = (data: SclaApplication) => {
    const { backgroundInfo: background } = data;

    const isEthnicityOther = checkIsEthnicityOther(background.ethnicity);
    const isLanguageOther = checkIsLanguageOther(
      background.primaryLanguage,
    );
    const isHearAboutUsOther = checkIsHearAboutUsOther(
      background.hearAboutUs,
    );

    const isHearAboutUsTxter = checkIsHearAboutUsTxter(
      background.hearAboutUs,
    );

    const map: IUpdateSclaAppBackgroundForm = {
      firstName: handleNullString(user?.firstName ?? null),
      lastName: handleNullString(user?.lastName ?? null),
      email: handleNullString(background.email),
      studentPhoneNumber: handleNullString(background.studentPhoneNumber),
      parentPhoneNumber: handleNullString(background.parentPhoneNumber),
      streetAddress: handleNullString(background.streetAddress),
      cityAddress: handleNullString(background.cityAddress),
      stateAddress: handleNullString(background.stateAddress),
      zipAddress: handleNullString(background.zipAddress),
      dob: isDateString(background.dob)
        ? new Date(background.dob)
        : new Date(),
      instagramHandle: handleNullString(background.instagramHandle),
      ethnicity: isEthnicityOther
        ? 'other'
        : handleNullString(background.ethnicity),
      ethnicityOther: isEthnicityOther
        ? handleNullString(background.ethnicity)
        : '',
      gradeLevel: handleNullString(background.gradeLevel || ''),
      primaryLanguage: isLanguageOther
        ? 'other'
        : handleNullString(background.primaryLanguage),
      primaryLanguageOther: isLanguageOther
        ? handleNullString(background.primaryLanguage)
        : '',

      hearAboutUs: isHearAboutUsOther
        ? 'other'
        : handleNullString(background.hearAboutUs),
      hearAboutUsOther: isHearAboutUsOther
        ? handleNullString(background.hearAboutUs)
        : '',
      recommendedBy: isHearAboutUsTxter
        ? handleNullString(background.recommendedBy)
        : '', // Set the recommender's name if applicable

      transportation: handleNullString(background.transportation),
      appliedBefore: handleNullString(background.appliedBefore),
    };
    setFormValue(map);
  };

  useEffect(() => {
    mapDefaultVales(app);
  }, [app]);

  const onSubmit = async () => {
    try {
      const isValid = getFormValidationStatus(model.check(formValue));
      // verify that form is valid
      if (isValid) {
        const {
          primaryLanguageOther,
          ethnicityOther,
          hearAboutUsOther,
          ...formData
        } = formValue;
        // construct name
        const name = `${formValue.firstName} ${formValue.lastName}`;
        // get ethnicity
        const ethnicity =
          formValue.ethnicity.toLocaleLowerCase() === 'other'
            ? ethnicityOther
            : formValue.ethnicity;

        // get language
        const primaryLanguage =
          formValue.primaryLanguage.toLocaleLowerCase() === 'other'
            ? primaryLanguageOther
            : formValue.primaryLanguage;

        const hearAboutUs =
          formValue.hearAboutUs.toLocaleLowerCase() === 'other'
            ? hearAboutUsOther
            : formValue.hearAboutUs;

        // const { hearAboutUs } = formValue;

        const payload = {
          ...formData,
          ethnicity,
          name,
          primaryLanguage,
          hearAboutUs,
        };

        setIsLoading(true);
        const response = await updateSclaAppBackground(app._id, payload);
        openSuccessToaster('Saved Background Info', 3000);
        setApplication({
          ...app,
          backgroundInfo: response.backgroundInfo,
        });
        setIsLoading(false);
        navToSclaAppFamily();
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: IUpdateSclaAppBackgroundForm) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
