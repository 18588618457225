import React from 'react';
import Alert from '../../../components/Elements/Alert';

export default function ApplicationAlertInfo() {
  return (
    <Alert type="info">
      <p>
        <b>Required</b> fields are marked with:{' '}
        <span style={{ color: 'red', fontSize: '1em' }}>* (Required)</span>
      </p>
      <p>You can omit information for these fields by typing in `N/A`.</p>
    </Alert>
  );
}
