import React from 'react';
import CustomForm from 'components/Form/CustomForm';
import { NavLink, useOutletContext } from 'react-router-dom';
import { COLOR_GREEN } from 'constants/colors';
import styled from 'styled-components';
import {
  getSclaAppFamilyPath,
  getSclaAppPromptsPath,
} from 'utils/navigation';
import useScrollToTop from 'hooks/useScrollToTop';

// import Alert from 'components/Elements/Alert';
import useUpdateSealsAppFamily from '../hooks/useUpdateSclaAppSchool';
import { SclaApplicationOutletContext } from './SclaAppLayout';
import ApplicationAlertInfo from '../components/ApplicationAlertInfo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px 16px 50px 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h1 {
    margin-bottom: 10px;
    text-align: center;
    line-height: 28px;
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const NavButtonLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  background-color: ${COLOR_GREEN};
  padding: 10px 15px;
  border-radius: 4px;
  p {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
  }
`;

export default function SclaApplicationSchool() {
  useScrollToTop();

  const { application, setApplication }: SclaApplicationOutletContext =
    useOutletContext();

  const { formValue, isLoading, model, fields, onChange, onSubmit } =
    useUpdateSealsAppFamily(application, setApplication);

  return (
    <>
      <Header>
        <h1 style={{ fontSize: '2.1em' }}>School Information</h1>
        <NavButtons>
          <NavButtonLink to={getSclaAppFamilyPath()}>
            <p>Previous</p>
          </NavButtonLink>
          <NavButtonLink to={getSclaAppPromptsPath()}>
            <p>Next</p>
          </NavButtonLink>
        </NavButtons>
        <ApplicationAlertInfo />
      </Header>

      <Container>
        <CustomForm
          onChange={onChange}
          onSubmit={onSubmit}
          fields={fields}
          isLoading={isLoading}
          model={model}
          formValue={formValue}
          isDisabled={false}
          submitLabel="Save and Continue"
          hasChanges={false}
        />
      </Container>
    </>
  );
}
