import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import {
  getFormValidationStatusAsync,
  scrollToFormErr,
} from 'utils/form.util';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import formValidation from 'constants/validation';
import { FormField } from 'components/Form/CustomForm';
import { PICKER_ETHNICITY, PICKER_GRADE } from 'constants/picker';
// import SCHOOL_LIST from 'constants/schools';
import { useSearchParams } from 'react-router-dom';
import signUp from '../api/signUp';
import getSchoolList from '../api/getSchoolList';

export interface ISignUpForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  school: string;
  ethnicity: string;
  ethnicityOther: string;
  grade: string;
  email: string;
  password: string;
  verifyPassword: string;
}

const { errorMessages: err } = formValidation;

// Extract schema types for form validation
const { StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  firstName: StringType().isRequired(err.firstName),
  lastName: StringType().isRequired(err.lastName),
  phoneNumber: StringType().isRequired(err.phoneNumber),
  school: StringType().isRequired(err.school),
  grade: StringType().isRequired(err.grade),
  ethnicity: StringType().isRequired(err.ethnicity),
  email: StringType().isRequired(err.email).isEmail(err.email),
  password: StringType()
    .isRequired(err.password)
    .minLength(6, 'Password must be 6 characters long.'),
  verifyPassword: StringType()
    .isRequired('Please confirm your Password.')
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, err.verifyPassword),
});

export default function useSignup() {
  const [searchParams] = useSearchParams();
  const qFirstName = searchParams.get('firstName') as string;
  const qLastName = searchParams.get('lastName') as string;
  const qEmail = searchParams.get('email') as string;
  const qPhoneNumber = searchParams.get('phoneNumber') as string;
  const qSchool = searchParams.get('schoolName') as any;
  const qGrade = searchParams.get('gradeLevel') as any;
  const qEthnicity = searchParams.get('ethnicity') as any;
  const qEthnicityOther = searchParams.get('ethnicityOther') as any;
  const [schoolList, setSchoolList] = useState<string[]>([]);

  const fields: FormField[] = [
    {
      name: 'firstName',
      type: 'text',
      label: 'First Name',
      size: 'lg',
      data: null,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
      size: 'lg',
      data: null,
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      size: 'lg',
      data: null,
    },
    {
      name: 'phoneNumber',
      type: 'phoneNumber',
      label: 'Phone Number',
      size: 'lg',
      data: null,
    },
    {
      name: 'school',
      type: 'autoComplete',
      label: 'School Name',
      size: 'lg',
      data: schoolList,
    },

    {
      name: 'grade',
      type: 'select',
      label: 'Grade Level',
      size: 'lg',
      data: {
        otherLabel: 'Enter your grade',
        picker: PICKER_GRADE,
        searchable: false,
      },
    },

    {
      name: 'ethnicity',
      type: 'select',
      label: 'Ethnicity',
      size: 'lg',
      data: {
        otherLabel: 'Enter your ethnicity',
        picker: PICKER_ETHNICITY,
        searchable: false,
      },
    },

    {
      name: 'password',
      type: 'password',
      label: 'Password',
      size: 'lg',
      data: null,
    },
    {
      name: 'verifyPassword',
      type: 'password',
      label: 'Verify Password',
      size: 'lg',
      data: null,
    },
  ];

  const [formValue, setFormValue] = useState<ISignUpForm>({
    firstName: qFirstName || '',
    lastName: qLastName || '',
    email: qEmail || '',
    phoneNumber: qPhoneNumber || '',
    school: qSchool || '',
    grade: qGrade || '',
    ethnicity: qEthnicity || '',
    ethnicityOther: qEthnicityOther || '',
    password: '',
    verifyPassword: '',
  }); // set default form values

  const [isLoading, setIsLoading] = useState(false); // flag for submission process

  useEffect(() => {
    const fetchSchoolList = async () => {
      const schools = await getSchoolList();
      setSchoolList(schools);
    };

    fetchSchoolList();

    if (
      qFirstName &&
      qLastName &&
      qEmail &&
      qSchool &&
      qPhoneNumber &&
      qGrade &&
      qEthnicity
    ) {
      setTimeout(() => {
        const element = document.getElementById('password');
        if (element) {
          // scroll to error input
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 150);
    }
  }, [
    qFirstName,
    qLastName,
    qEmail,
    qSchool,
    qPhoneNumber,
    qGrade,
    qEthnicity,
  ]);
  /**
   * Makes server request to validate user credentials.
   * Users with valid credentials are authenticated and
   * redirect to the authenticated routes
   */
  const onSubmit = async () => {
    try {
      const isValid = await getFormValidationStatusAsync(
        model.check(formValue),
      );

      // // // Check if the selected school is in the SCHOOL_LIST
      if (!schoolList.includes(formValue.school)) {
        const element = document.getElementById('school-name-field');
        if (element) {
          // scroll to error input
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        return;
      }

      // verify that form is valid
      if (isValid) {
        setIsLoading(true);

        // pull out signup data without verify password field
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { verifyPassword: remove, ...signUpData } = formValue;
        const name = `${formValue.firstName} ${formValue.lastName}`;
        await signUp({ ...signUpData, name });

        // auto-login using firebase auth
        const auth = getAuth();
        await signInWithEmailAndPassword(
          auth,
          formValue.email,
          formValue.password,
        );

        // show spinner
        setIsLoading(false);
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: any) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
