import Button from 'components/Elements/Button/Button';
import React from 'react';
import { Form } from 'rsuite';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import CheckboxFormField from './Input/Base/CheckboxFormField';
import TextFormField from './Input/Base/TextFormField';
import SelectFormField from './Input/Base/SelectFormField';
import DatePickerFormField from './Input/Other/DatePickerFormField';
import PhoneNumberFormField from './Input/Base/PhoneNumberFormField';
import TextAreaFormField from './Input/Base/TextareaFormField';
import AutoCompleteFormField from './Input/Base/AutoCompleteFormField';
import MultiSelectFormField from './Input/Base/MultiSelectFormField';
import ActivitiesFields from './Input/Multi/ActivitiesFields';

export type FormFieldSize = 'sm' | 'lg';

export type FormField = {
  name: string;
  label: string;
  type: string;
  data: any;
  size: FormFieldSize;
  required?: boolean;
  id?: string;
};

export interface IFormFieldMap {
  [key: string]: React.ComponentType<any>; // Index signature for string keys
}

interface ICustomFormProps {
  fields: FormField[];
  isLoading: boolean;
  model: any;
  formValue: any;
  isDisabled: boolean;
  submitLabel: string;
  hasChanges: boolean;
  submitBtnSize?: ButtonSize;
  onCancel?: () => void;
  onChange: (val: any) => void;
  onSubmit: () => {};
}

const CustomFormContainer = styled(Form)`
  display: flex;
  gap: 0px;
  flex-wrap: wrap;
  width: 100%;
`;

const SubmitButtonContainer = styled.div`
  flex: 1 1 100%;
  justify-content: flex-end;
  display: flex;
  gap: 16px;
  margin: 0.5rem 0.5rem 0 0.5rem;
`;

const SectionHeader = styled.div`
  cursor: pointer;
  background-color: white;
  padding: 10px;
  margin: 10px 0;
  border: 4px solid #0277b9;
  border-radius: 4px;
  width: 100%;
  color: #0277b9;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FormFieldMap: IFormFieldMap = {
  text: TextFormField,
  textarea: TextAreaFormField,
  email: TextFormField,
  password: TextFormField,
  checkbox: CheckboxFormField,
  select: SelectFormField,
  multi_select: MultiSelectFormField,
  date: DatePickerFormField,
  phoneNumber: PhoneNumberFormField,
  autoComplete: AutoCompleteFormField,
  scla_activities: ActivitiesFields,
};

export function CustomFormField({
  isDisabled,
  field,
  formValue,
  id,
  onChange,
}: {
  id: string;
  field: FormField;
  isDisabled: boolean;
  formValue: any;
  onChange: (val: any) => void;
}) {
  if (FormFieldMap[field.type]) {
    const FieldMap = FormFieldMap[field.type];

    if (
      field.type === 'text' ||
      field.type === 'email' ||
      field.type === 'password' ||
      field.type === 'phoneNumber'
    ) {
      return (
        <FieldMap
          id={id}
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'textarea') {
      return (
        <FieldMap
          id={id}
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'autoComplete') {
      return (
        <FieldMap
          id={id}
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          formValue={formValue}
          onChange={onChange}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'select') {
      return (
        <FieldMap
          id={id}
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          formValue={formValue}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'multi_select') {
      return (
        <FieldMap
          id={id}
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          formValue={formValue}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'date') {
      return (
        <FieldMap
          id={id}
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'scla_activities') {
      return (
        <FieldMap
          id={id}
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          required={field.required || false}
        />
      );
    }

    return (
      <FieldMap
        id={id}
        size="lg"
        disabled={isDisabled}
        type={field.type}
        name={field.name}
        label={field.label}
        required={field.required || false}
      />
    );
  }

  return <div />;
}

export default function FamilyForm(props: ICustomFormProps) {
  const {
    submitBtnSize,
    hasChanges,
    isDisabled,
    fields,
    isLoading,
    model,
    formValue,
    submitLabel,
    onCancel,
    onChange,
    onSubmit,
  } = props;

  const [isFatherOpen, setIsFatherOpen] = React.useState(false);
  const [isMotherOpen, setIsMotherOpen] = React.useState(false);
  const [isGuardianOpen, setIsGuardianOpen] = React.useState(false);

  const toggleFather = () => setIsFatherOpen(!isFatherOpen);
  const toggleMother = () => setIsMotherOpen(!isMotherOpen);
  const toggleGuardian = () => setIsGuardianOpen(!isGuardianOpen);

  const renderFields = (section: string) =>
    fields
      .filter(field => field.name.startsWith(section))
      .map(field => (
        <CustomFormField
          id={field.id || field.name}
          formValue={formValue}
          onChange={(val: any) => {
            const payload = { ...formValue };
            payload[field.name] = val;
            onChange(payload);
          }}
          isDisabled={isDisabled}
          key={field.name}
          field={field}
        />
      ));

  return (
    <CustomFormContainer
      fluid
      model={model}
      formValue={formValue}
      onChange={onChange}
    >
      {fields
        .filter(
          field =>
            !field.name.startsWith('father') &&
            !field.name.startsWith('mother') &&
            !field.name.startsWith('guardian'),
        )
        .map(field => (
          <CustomFormField
            id={field.id || field.name}
            formValue={formValue}
            onChange={(val: any) => {
              const payload = { ...formValue };
              payload[field.name] = val;
              onChange(payload);
            }}
            isDisabled={isDisabled}
            key={field.name}
            field={field}
          />
        ))}

      <div>
        <b style={{ fontSize: '1rem', color: 'red' }}>
          Click one of the three sections below to fill out more
          information regarding parent or guardian.
        </b>
      </div>

      <SectionHeader onClick={toggleMother}>
        Mother Information {isMotherOpen ? '▲' : '▼'}
      </SectionHeader>
      <Collapse isOpened={isMotherOpen}>
        <RowContainer>{renderFields('mother')} </RowContainer>
      </Collapse>

      <SectionHeader onClick={toggleFather}>
        Father Information {isFatherOpen ? '▲' : '▼'}
      </SectionHeader>
      <Collapse isOpened={isFatherOpen}>
        <RowContainer>{renderFields('father')} </RowContainer>{' '}
      </Collapse>

      <SectionHeader onClick={toggleGuardian}>
        Guardian Information {isGuardianOpen ? '▲' : '▼'}
      </SectionHeader>
      <Collapse isOpened={isGuardianOpen}>
        <RowContainer>{renderFields('guardian')}</RowContainer>
      </Collapse>

      <SubmitButtonContainer>
        {hasChanges && onCancel && (
          <Button
            size="sm"
            type="button"
            isLoading={false}
            onClick={onCancel}
            color="grey"
          >
            <p> Cancel</p>
          </Button>
        )}

        <Button
          size={submitBtnSize || 'sm'}
          type="submit"
          isLoading={isLoading}
          onClick={onSubmit}
        >
          <p> {submitLabel} </p>
        </Button>
      </SubmitButtonContainer>
    </CustomFormContainer>
  );
}

FamilyForm.defaultProps = {
  onCancel: undefined,
  submitBtnSize: undefined,
};
