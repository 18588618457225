import styled from 'styled-components';
import React from 'react';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import bannerImage from "assets/images/scla-banner.svg"; // Adjust the path as needed
import Logo from 'components/Images/Logo';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const PageContainer = styled.div`
  height: 100dvh;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: center; /* Align items vertically */
  justify-content: flex-start; /* Keep them aligned to the left */
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 25px 0 25px 150px;
  position: fixed;

  @media (min-width: 200px) and (max-width: 1279px) {
    justify-content: center;
    padding: 0;
  }
`;
const Container = styled.div`
  flex-grow: 0; /* Ensures it does not expand beyond its width */
  width: 100%;
  max-width: 400px; /* Ensures it doesn't get too large */
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border-radius: 10px;
  padding: 24px;
  z-index: 1;

  @media (min-width: 768px) {
    width: 400px;
    flex-shrink: 0; /* Prevents shrinking */
  }

  @media (min-width: 200px) and (max-width: 767px) {
    margin: 15px;
  }
`;

const FormHeader = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const LogoContainer = styled.div`
  img {
    width: 225px;
  }
`;

const TitleContainer = styled.div`
  p {
    text-align: center;
  }

  p:nth-child(2) {
    font-weight: 600;
  }
`;

const SclaBanner = styled.div`
   display: none;

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center;
    align-items: center;
    height: 249px;
    min-height: 249px; /* Prevents it from shrinking */
    width: 100%; /* Ensure it takes up full width */
    position: relative; /* Helps keep elements contained */
    overflow: hidden; /* Prevents text from overflowing */

    img {
      height: 100%; /* Ensures the image scales with the container */
      width: 100%; /* Makes it responsive */
      object-fit: contain; /* Ensures the full image is visible */
      margin-right: 29px;
    }
  }

  @media (min-width: 1500px) {
    img {
      margin-right: 35px;
    }
  }
`;
function ForgotPassword() {
  return (
    <PageContainer>
      <Container>
        <FormHeader>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <TitleContainer>
            <ParagraphText marginTop={10}>
              Enter your profile email address and we will send you
              instructions to reset your password.
            </ParagraphText>
            <ParagraphText>
              You may have to check your spam or junk folder.
            </ParagraphText>
          </TitleContainer>
        </FormHeader>

        <ForgotPasswordForm />
      </Container>
      <SclaBanner>  <img src={bannerImage} alt="SCLA Banner" />
      </SclaBanner>
    </PageContainer>
  );
}

export default ForgotPassword;
