/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import ParticlesBackground from 'components/Particles/ParticlesBackground';
import bgImage from "assets/images/scla-students.svg"; // Adjust the path as needed
import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
// import Interests from './Interests';
// import Home from './Home';

const StyledContainer = styled.div`
  padding: 0px;
  height: 100%;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom, #50B3EA, #0277B8),
              url(${() => bgImage}); /* Ensure this path is correct */
  overflow: hidden;
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling */
  background-blend-mode: overlay; /* Allows both gradient & image to be visible */
`;
export function AuthRoutes() {
  return (
    <StyledContainer>
      {/* <ParticlesBackground /> */}
      <Routes>
        {/* <Route path="" element={<Home />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        {/* <Route path="/interests" element={<Interests />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </StyledContainer>
  );
}
