import React from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import useScrollToTop from 'hooks/useScrollToTop';
import {
  getSclaAppSchoolPath,
  getSclaAppSignaturePath,
  getSclaAppTranscriptsPath,
} from 'utils/navigation';
import { COLOR_GREEN } from 'constants/colors';
import styled from 'styled-components';
// import Alert from 'components/Elements/Alert';
import { UserSession } from 'types';
import { SclaApplicationOutletContext } from './SclaAppLayout';
import AppPromptsForm from '../components/AppPromptsForm';
import ReturnerAppPromptsForm from '../components/ReturnerAppPromptsForm';
import ApplicationAlertInfo from '../components/ApplicationAlertInfo';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h1 {
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 28px;
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const NavButtonLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  background-color: ${COLOR_GREEN};
  padding: 10px 15px;
  border-radius: 4px;
  p {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
  }
`;

export default function SclaApplicationPrompts({
  user,
}: {
  user: UserSession;
}) {
  useScrollToTop();
  const { application, setApplication }: SclaApplicationOutletContext =
    useOutletContext();

  if (user.type === 'txter') {
    return (
      <>
        <Header>
          <h1 style={{ fontSize: '2.1em' }}>Prompts</h1>
          <NavButtons>
            <NavButtonLink to={getSclaAppSchoolPath()}>
              <p>Previous</p>
            </NavButtonLink>
            <NavButtonLink to={getSclaAppTranscriptsPath()}>
              <p>Next</p>
            </NavButtonLink>
          </NavButtons>
          <ApplicationAlertInfo />
        </Header>

        <ReturnerAppPromptsForm
          application={application}
          setApplication={setApplication}
        />
      </>
    );
  }

  return (
    <>
      <Header>
        <h1 style={{ fontSize: '2.1em' }}>Prompts</h1>
        <NavButtons>
          <NavButtonLink to={getSclaAppSchoolPath()}>
            <p>Previous</p>
          </NavButtonLink>
          <NavButtonLink to={getSclaAppSignaturePath()}>
            <p>Next</p>
          </NavButtonLink>
        </NavButtons>
        <ApplicationAlertInfo />
      </Header>

      <AppPromptsForm
        application={application}
        setApplication={setApplication}
      />
    </>
  );
}
