import { makeGetRequest } from 'lib/axios';
// import { UserSession } from 'types';

/**
 * Make API request to get list of schools from DB
 * @returns
 */
const getSchoolList = async (): Promise<string[]> => {
  const url = `/schools-list/getSchools`;
  const response = await makeGetRequest(url);

  //   console.log('response', response.data.payload);
  return response.data.payload;
};

export default getSchoolList;
